import React, { MutableRefObject, memo, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { Box } from "@mui/material";
import { HashRouter, Route, Routes } from "react-router-dom";
import DashBoard from "../BackOffice/DashBoard/DashBoard";
import { saveDefaultSettings, savePrivilege } from "./globalfunctions";
import axios from "axios";
import { useLanguage } from "./LanguageContext";
import { setLocalStorage, useLocalStorage } from "../../hooks/app/useLocalStorage";
import StatmentsContainer from "./StatementsContainer";

import ServerSelectionModal, { TServerDataLocalStorage } from "./ServerSelectionModal";

const Invoice = React.lazy(() => import("../../Pages/BackOffice/Invoice"));
const Invoice2 = React.lazy(() => import("../../Pages/BackOffice/Invoice2"));
const MainMenu = React.lazy(() => import("../../Pages/BackOffice/MainMenu"));
const Goods = React.lazy(() => import("../../Pages/BackOffice/Goods"));
const HomeScreen = React.lazy(() => import("../../Pages/BackOffice/HomeScreen"));
const Accounts = React.lazy(() => import("../../Pages/BackOffice/Accounts"));
const Vouchers = React.lazy(() => import("../../Pages/BackOffice/Vouchers"));
const Statistics = React.lazy(() => import("../../Pages/BackOffice/Statisctics"));
const PosMainMenu = React.lazy(() => import("../../Pages/POS/PosMainMenu"));
const PosSales = React.lazy(() => import("../../Pages/POS/PosSales"));
const PosSales2 = React.lazy(() => import("../../Pages/POS/PosSales2"));
const Proforma = React.lazy(() => import("../../Pages/BackOffice/Proforma"));
const AttMainMenu = React.lazy(() => import("../../Pages/Attendance/AttMainMenu"));
const Attendance = React.lazy(() => import("../../Pages/Attendance/Attendance"));
const Employee = React.lazy(() => import("../../Pages/Attendance/Employees"));
const Imports = React.lazy(() => import("../../Pages/Attendance/Imports"));
const Exports = React.lazy(() => import("../../Pages/Attendance/Exports"));
const ReportBuilder = React.lazy(() => import("../../Pages/ReportBuilder/ReportBuilder"));

function NavContainer({ alertHandler, setuseDarkMode }) {
    const [settings, setsettings] = useState({});
    const [cookies, setCookie] = useState(localStorage.getItem("token") ?? "");
    const [isLogedIn, setisLogedIn] = useState(false);
    const [admin, setadmin] = useState({
        pc_info: { lic: [] },
        name: "",
        dataname: "",
        token: "",
        rank: "",
    });

    const [showDashboard, setshowDashboard] = useState(true);
    const dataName = localStorage.getItem("dbname");

    const { lang, langHandler } = useLanguage();
    const serverData = () => {
        const serversData = localStorage.getItem("serversData");
        if (serversData) {
            const serversDataLocalStorage: TServerDataLocalStorage = JSON.parse(serversData);
            const selectedServer = serversDataLocalStorage.selectedServer;
            if (selectedServer) {
                return {
                    host: selectedServer.host,
                    port: selectedServer.port,
                };
            }
        }
        return {
            host: "127.0.0.1",
            port: 8000,
        };
    };
    const serverHostRef = useRef(serverData().host);
    const serverPortRef = useRef(serverData().port);
    const reqType = serverPortRef.current.toString() === "443" ? "https" : "http";

    const cookiesHandler = (e) => {
        setCookie(e);
        localStorage.setItem("token", e);
    };
    useEffect(() => {
        document.title = " PYX Technologies - BackOffice ";
        logIn();

        try {
            const serversData = localStorage.getItem("serversData");
            if (serversData) {
                const serversDataLocalStorage: TServerDataLocalStorage = JSON.parse(serversData);
                const selectedServer = serversDataLocalStorage.selectedServer;
                if (selectedServer) {
                    serverHostRef.current = selectedServer.host;
                    serverPortRef.current = selectedServer.port;
                }
            }
        } catch (error) {
            console.log(error);
        }

    }, []);
    const routeName = window.location.href.split("#")[1];

    const outerStatementsContainerAPI: TStatementsContainerAPI = useRef({});

    const appRoutes = [
        {
            path: "/mainmenu",
            lic: ["All", "backoffice"],
            element: (
                <MainMenu
                    settings={settings}
                    postdata={postdata}
                    cookies={cookies}
                    setshowDashboard={setshowDashboard}
                    setuseDarkMode={setuseDarkMode}
                    logIn={logIn}
                    outerStatementsContainerAPI={outerStatementsContainerAPI}
                />
            ),
        },
        {
            path: "/goods",
            lic: ["All", "backoffice", "pos"],
            element: (
                <Goods
                    settings={settings}
                    postdata={postdata}
                    cookies={cookies}
                    setshowDashboard={setshowDashboard}
                />
            ),
        },
        {
            path: "/purchase2",
            lic: ["All", "backoffice"],
            element: (
                <Invoice2
                    settings={settings}
                    admin={admin}
                    title="Purchase"
                    outerStatementsContainerAPI={outerStatementsContainerAPI}
                />
            ),
        },
        {
            path: "/sales2",
            lic: ["All", "backoffice"],
            element: (
                <Invoice2
                    settings={settings}
                    admin={admin}
                    title="Sales"
                    outerStatementsContainerAPI={outerStatementsContainerAPI}
                />
            ),
        },
        {
            path: "/proforma2",
            lic: ["All", "backoffice"],
            element: (
                <Invoice2
                    settings={settings}
                    admin={admin}
                    title="Proforma"
                    outerStatementsContainerAPI={outerStatementsContainerAPI}
                />
            ),
        },
        {
            path: "/purchase",
            lic: ["All", "backoffice"],
            element: (
                <Invoice
                    settings={settings}
                    admin={admin}
                    postdata={postdata}
                    cookies={cookies}
                    setshowDashboard={setshowDashboard}
                    title="Purchase"
                />
            ),
        },
        {
            path: "/sales",
            lic: ["All", "backoffice"],
            element: (
                <Invoice
                    settings={settings}
                    admin={admin}
                    postdata={postdata}
                    cookies={cookies}
                    setshowDashboard={setshowDashboard}
                />
            ),
        },
        {
            path: "/proforma",
            lic: ["All", "backoffice"],
            element: (
                <Proforma
                    settings={settings}
                    admin={admin}
                    postdata={postdata}
                    cookies={cookies}
                    setshowDashboard={setshowDashboard}
                />
            ),
        },
        {
            path: "/accounts",
            lic: ["All", "backoffice", "pos"],
            element: (
                <Accounts
                    settings={settings}
                    admin={admin}
                    postdata={postdata}
                    cookies={cookies}
                    setshowDashboard={setshowDashboard}
                />
            ),
        },

        {
            path: "/vouchers",
            lic: ["All", "backoffice"],
            element: (
                <Vouchers
                    settings={settings}
                    admin={admin}
                    postdata={postdata}
                    cookies={cookies}
                    setshowDashboard={setshowDashboard}
                />
            ),
        },

        {
            path: "/statistics",
            lic: ["All", "backoffice"],
            element: (
                <Statistics
                    settings={settings}
                    admin={admin}
                    postdata={postdata}
                    cookies={cookies}
                    setshowDashboard={setshowDashboard}
                />
            ),
        },

        {
            path: "/pos/mainmenu",
            lic: ["All", "pos"],
            element: (
                <PosMainMenu
                    settings={settings}
                    admin={admin}
                    postdata={postdata}
                    cookies={cookies}
                    setshowDashboard={setshowDashboard}
                    recieptPrint={recieptPrint}
                    logIn={logIn}
                />
            ),
        },

        {
            path: "/pos/sales",
            lic: ["All", "pos"],
            element: (
                <PosSales
                    settings={settings}
                    admin={admin}
                    postdata={postdata}
                    cookies={cookies}
                    setshowDashboard={setshowDashboard}
                    recieptPrint={recieptPrint}
                />
            ),
        },

        {
            path: "/pos/sales2",
            lic: ["All", "pos"],
            element: (
                <PosSales2
                    settings={settings}
                    admin={admin}
                    postdata={postdata}
                    cookies={cookies}
                    setshowDashboard={setshowDashboard}
                    recieptPrint={recieptPrint}
                />
            ),
        },
        {
            path: "/att/mainmenu",
            lic: ["All", "payroll"],
            element: (
                <AttMainMenu
                    settings={settings}
                    admin={admin}
                    postdata={postdata}
                    cookies={cookies}
                    setshowDashboard={setshowDashboard}
                    logIn={logIn}
                />
            ),
        },
        {
            path: "/att/attendance",
            lic: ["All", "payroll"],
            element: (
                <Attendance
                    settings={settings}
                    admin={admin}
                    postdata={postdata}
                    cookies={cookies}
                    setshowDashboard={setshowDashboard}
                />
            ),
        },
        {
            path: "/att/employees",
            lic: ["All", "payroll"],
            element: (
                <Employee
                    settings={settings}
                    admin={admin}
                    postdata={postdata}
                    cookies={cookies}
                    setshowDashboard={setshowDashboard}
                />
            ),
        },
        {
            path: "/att/imports",
            lic: ["All", "payroll"],
            element: (
                <Imports
                    settings={settings}
                    admin={admin}
                    postdata={postdata}
                    cookies={cookies}
                    setshowDashboard={setshowDashboard}
                />
            ),
        },
        {
            path: "/att/Exports",
            lic: ["All", "payroll"],
            element: (
                <Exports
                    settings={settings}
                    admin={admin}
                    postdata={postdata}
                    cookies={cookies}
                    setshowDashboard={setshowDashboard}
                />
            ),
        },
        {
            path: "/rep/reportbuilder",
            lic: ["All", "reportbuilder"],
            element: <ReportBuilder postdata={postdata} />,
        },
    ];

    return (
        <HashRouter>
            <div className="h-screen    overflow-hidden " id="app1" style={{ backgroundColor: "black" }}>
                <Box
                    className="App mx-auto my-auto h-screen   overflow-hidden "
                    id="app2"
                    sx={{ backgroundColor: "system.primaryBG", color: "text.secondary" }}
                >
                    <DashBoard
                        showDashboard={showDashboard}
                        postdata={postdata}
                        outerStatementsContainerAPI={outerStatementsContainerAPI}
                    />
                    <StatmentsContainer
                        outerStatementsContainerAPI={outerStatementsContainerAPI}
                        postdata={postdata}
                    />
                    <React.Suspense fallback={<div>Loading...</div>}>
                        <Routes>
                            <Route
                                path={"/"}
                                element={
                                    <HomeScreen
                                        settings={settings}
                                        postdata={postdata}
                                        setadmin={setadmin}
                                        setCookie={cookiesHandler}
                                        setshowDashboard={setshowDashboard}
                                        setisLogedIn={setisLogedIn}
                                        setsettings={setsettings}
                                        setuseDarkMode={setuseDarkMode}
                                    />
                                }
                            />

                            <>
                                {appRoutes.map((el, idx) => {
                                    let show = false;
                                    admin.pc_info.lic.forEach((sl) => {
                                        if (el.lic.includes(sl)) {
                                            show = true;
                                        }
                                    });

                                    return (
                                        <Route
                                            key={idx}
                                            path={el.path}
                                            element={<PageLoader show={show}> {el.element}</PageLoader>}
                                        />
                                    );
                                })}
                            </>
                        </Routes>
                    </React.Suspense>
                </Box>
            </div>
        </HashRouter>
    );

    function logIn() {
        const url = "admins/Login/";
        const data = { password: "", token: cookies };
        const cbnf = (res) => {
            if (res.data.info === "success") {
                window.localStorage.setItem(
                    "admin",
                    JSON.stringify({
                        name: res.data.name,
                        dataname: res.data.dataname,
                        rank: res.data.rank,
                        pc_info: res.data.pc_info,
                    })
                );
                setadmin({
                    name: res.data.name,
                    dataname: res.data.dataname,
                    token: res.data.token,
                    rank: res.data.rank,
                    pc_info: res.data.pc_info,
                });
                setisLogedIn(true);
                setsettings(res.data.settings);
                setuseDarkMode(res.data.settings.system.default.use_dark_mode);
                langHandler(res.data.settings.system.default.language ?? 1);
                saveDefaultSettings(res.data.settings);
                savePrivilege(res.data.privilege);
            } else if (res.data.status === "expired" || res.data.status === "unauthurized") {
                window.location.href = routeName + "/";
            }
        };
        if (!(routeName === undefined || routeName === "/")) {
            postdata(url, cbnf, data, true);
        }
    }

    function PageLoader({ children, show }) {
        if (isLogedIn) {
            if (show) {
                return children;
            } else {
                return <React.Fragment> No Access</React.Fragment>;
            }
        } else {
            return <React.Fragment> Loging In...</React.Fragment>;
        }
    }
    // function recieptPrint(id, printer) {
    //     const originalElement = document.getElementById("divToPrintBefore" + id);

    //     // Clone the element
    //     const clonedElement = originalElement.cloneNode(true);
    //     clonedElement.id = "divToPrint";

    //     // Append the clone to the new parent
    //     document.body.appendChild(clonedElement);

    //     const x = document.title.toString();
    //     document.title = `print receipt-_-${JSON.stringify(printer)}`;
    //     document.title = x;
    // }
    async function recieptPrint(options: Electron.WebContentsPrintOptions, repId) {
        const originalElement = document.getElementById(repId);

        // Clone the element
        const clonedElement = originalElement.cloneNode(true) as HTMLElement;
        clonedElement.id = "divToPrint";

        // Append the clone to the new parent
        document.body.appendChild(clonedElement);
        const win = window as typeof window & { electronAPI: any };
        console.log(win.electronAPI);
        if (win.electronAPI) {
            win.electronAPI.print(options);
        } else {
            win.print();
        }
    }
    function postdata(url, callback, tdata, showmsg) {
        const data = { token: cookies, dataname: dataName };
        const fdata = Object.assign(data, tdata);

        axios({
            url: `${reqType}://` + serverHostRef.current + `:${serverPortRef.current.toString()}/` + url,
            method: "post",
            headers: { "Content-Type": "application/json" },
            data: fdata,
        })
            .then((res) => {
                callback(res);
                console.log(res);
                if (res.data.info === "success") {
                    if (showmsg) {
                        alertHandler(res.data.status, res.data.msg);
                    }
                } else {
                    alertHandler(res.data.status, res.data.msg);
                }
            })
            .catch((err) => {
                alertHandler("error", err.message);
            });
    }
    async function apostdata(url, callback, tdata, showmsg) {
        const data = { token: cookies, dataname: dataName };
        const fdata = Object.assign(data, tdata);
        return await axios({
            url: `${reqType}://` + serverHostRef.current + `:${serverPortRef.current.toString()}/` + url,
            method: "post",
            headers: { "Content-Type": "application/json" },
            data: fdata,
        })
            .then(async (res) => {
                callback(res);
                console.log(res);
                if (res.data.info === "success") {
                    if (showmsg) {
                        alertHandler(res.data.status, res.data.msg);
                    }
                } else {
                    alertHandler(res.data.status, res.data.msg);
                }
                return res;
            })
            .catch((err) => {
                alertHandler("error", err.message);
            });
    }
}

export default memo(NavContainer);

export type TStatementsContainerAPI = MutableRefObject<{
    setShowStatementOfAccount?: (show: boolean) => void;
    setShowStatementOfItem?: (show: boolean) => void;
    setShowStockOverview?: (show: boolean) => void;
    setShowTrialBalance?: (show: boolean) => void;
    setTempHideAccount?: (show: boolean) => void;
    setTempHideItem?: (show: boolean) => void;
    setTempHideOverview?: (show: boolean) => void;
    setTempHideTrial?: (show: boolean) => void;
}>;
