import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Button from "../../app/Button";
import ReportLayout from "../../../Pages/ReportBuilder/ReportLayout";
import Modal from "../../app/Modal";
import { KeyboardArrowLeft, KeyboardArrowRight } from "@mui/icons-material";
import { IconButton } from "@mui/material";
import ArrowCircleRightOutlinedIcon from "@mui/icons-material/ArrowCircleRightOutlined";
import ArrowCircleLeftOutlinedIcon from "@mui/icons-material/ArrowCircleLeftOutlined";
import AppTheme from "../../app/AppTheme";

export default function ReportModal(props) {
    useEffect(() => {
        getReports(`id = '${props.reportId}'`, "*", false);
    }, []);

    const [reports, setreports] = useState([]);
    const [selectedreport, setselectedreport] = useState({});

    const [isLoading, setisLoading] = useState(true);
    const [config, setconfig] = useState({});
    const [structure, setstructure] = useState({});

    const [selectedIndex, setselectedIndex] = useState(0);
    try {
        return (
            <Modal
                show={props.showPrintModal}
                setshow={props.setshowPrintModal}
                title="Print"
                width="90vw"
                height="90vh"
            >
                <div className="relative flex h-full w-full flex-col  ">
                    <div
                        className="absolute flex w-full flex-row justify-between px-5  "
                        style={{ transform: "translate(-50%, -50%)", top: "50%", left: "50%" }}
                    >
                        <AppTheme theme="light">
                            <IconButton
                                style={{ width: "6rem", height: "6rem" }}
                                onClick={() => {
                                    if (selectedIndex > 0) {
                                        setReportHandler(reports[selectedIndex - 1], selectedIndex - 1);
                                    }
                                }}
                            >
                                <ArrowCircleLeftOutlinedIcon sx={{ transform: "scale(5)" }} />
                            </IconButton>

                            <IconButton
                                style={{ width: "6rem", height: "6rem" }}
                                onClick={() => {
                                    if (selectedIndex < reports.length - 1) {
                                        setReportHandler(reports[selectedIndex + 1], selectedIndex + 1);
                                    }
                                }}
                            >
                                <ArrowCircleRightOutlinedIcon sx={{ transform: "scale(5)" }} />
                            </IconButton>
                        </AppTheme>
                    </div>
                    <div className="flex h-full flex-row   justify-start overflow-y-auto p-2">
                        <div className="flex flex-col gap-2">
                            <Button
                                variant="outlined"
                                onClick={() => {
                                    try {
                                        props.onPrintFunc();
                                    } catch (err) {
                                        console.log(err);
                                    }
                                    const report = document.getElementById("divToPrint");
                                    document.body.appendChild(report);
                                    window.print();
                                    document.getElementById("reportCon").appendChild(report);
                                }}
                            >
                                Print
                            </Button>
                            <Button
                            onClick={() => {
                                document.getElementById("hide1").classList.toggle("hidden");
                                document.getElementById("hide2").classList.toggle("hidden");
                            }}
                            >toggle Deposit</Button>
                        </div>
                        {!isLoading && (
                            <ReportLayout
                                data={props.data}
                                report={selectedreport}
                                preview={true}
                                config={config}
                                structure={structure}
                            />
                        )}
                    </div>
                </div>
            </Modal>
        );
    } catch (err) {
        return <div>{err.toString()}</div>;
    }

    function getReports(filter, fields, multi) {
        let url = "reports/get_reports/";
        const cbnf = (res) => {
            if (res.data.info === "success") {
                setreports(res.data.data);
                setReportHandler(res.data.data[0], 0);
            }
        };
        const fdata = {
            filters: filter,
            fields: fields,
        };
        props.postdata(url, cbnf, fdata, false);
    }
    function setReportHandler(report, idx) {
        if (report !== undefined) {
            setselectedreport(report);
            setselectedIndex(idx);
            setisLoading(true);
            try {
                let cnf = eval(`(${report.config})`);
                setconfig(cnf);
            } catch (err) {
                console.log(err);
            }
            setTimeout(() => {
                let rep = report.structure;
                setstructure(rep);
                setisLoading(false);
            }, 600);
        }
    }
}
